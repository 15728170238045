import useFetch from '../useFetch';
import LoadingBlock from '../common/LoadingBlock';

const ResumeSkills = () => {
	const { data:skills, isLoading, error } = useFetch(process.env.REACT_APP_API_URL + 'skillGroups', 2, 'skills');
	//localStorage.setItem('skills', JSON.stringify(skills));
	
	return (
		<section className="sectionPod resumeSkills">
			<h3 className="title">Skills</h3>
			<LoadingBlock isLoading={isLoading} error={error} />
		    <div className="inlineList">
	    		{skills && skills.map((skillGroup) => (
    		    	<div className="listBlock" key={skillGroup.id}>
    	    			<h3 className="listTitle">{ skillGroup.title }</h3>
			    		<ul className="listList">
				    		{skillGroup && skillGroup.skills.map((skill) => (
				    			<li key={skill.id}>
				    				{ skill.title }
				    			</li>
		    				))}
			    		</ul>
    	    		</div>
	    		))}
		    	<div className="listFooter"></div>
    		</div>
		</section>
	);
}
export default ResumeSkills;