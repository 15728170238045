import { useState, useEffect } from 'react';
const useFetch = (url, timeout, localStore) => {

	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	
	
	useEffect(() => {
		const abortControl = new AbortController();

		function loadData(data) {
			setData(data);
			if(localStore !== undefined) {
				localStorage.setItem(localStore, JSON.stringify(data));
			}
			setIsLoading(false);
			setError(null);
		}
		
		function fetch_retry(url, options, n) {
		    return fetch(url, options).catch(function(error) {
		        if (n === 1) throw error;
		        return fetch_retry(url, options, n - 1);
		    });
		}
		
		function tryFetch(url, options, n) {
			fetch_retry(url, { signal: abortControl.signal }, 5)
			.then(res => {
				if(!res.ok) {
					throw Error("Data Unavailable. " + res.status + ": " + res.statusText);
				}
				return res.json();
			})
			.then(data => {
				loadData(data);
			})
			.catch(error => {
				if(error.name !== 'AbortError') {
					if (n !== 1) {
						tryFetch(url, options, --n);
					}
					setIsLoading(false);
					setError(error.message);
				}
			})
		}
		/*
		const localData = localStorage.getItem(localStore);
		if(localData === null) {
			tryFetch(url, { signal: abortControl.signal }, 5);
		} else {
			loadData(JSON.parse(localData));
			setError('Using local data.');
		}
		*/

		//setTimeout(function(){tryFetch(url, { signal: abortControl.signal }, 5);}, timeout*100);
		tryFetch(url, { signal: abortControl.signal }, 5);

		
		return () => abortControl.abort();
	},[url, timeout, localStore]);
	
	return { data, isLoading, error };
}
export default useFetch;