import useFetch from '../useFetch';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';

const ResumeOverview = () => {
	const { id } = useParams();
	const { data:resumeInfo } = useFetch(process.env.REACT_APP_API_URL + 'coverLetter' + (typeof id === 'undefined' ? '' : '?id=' +  id), 1 );
	const [ overLetter, setCoverLetter] = useState(null);
	const [ letterTitle, setLetterTitle] = useState(null);
	
	useEffect(() => {
        if(resumeInfo && typeof resumeInfo[0] !== 'undefined') {
        	setLetterTitle(resumeInfo[0].organization + ', ' + resumeInfo[0].job_title);
        	setCoverLetter(parse(resumeInfo[0].coverLetter));
        } else {
        	setLetterTitle('Overview');
        	setCoverLetter(
        		<div>
        			<p>
        				When it comes to application development, my strengths are definitely geared to the front-end.  I have a passion for the user experience.  To me, a bad UI is an affront to the user.  It is inexcusable to make the user work to understand how to use an application.  An application should be a bridge to a solution rather than a barrier.  When it comes to programming, the end user is the person who matters to me the most.  I can’t stand the thought of letting them down!
        			</p>
        			<p>
        			    From planning to deployment and maintenance, I can help you at every step of the project life cycle. <a href="https://www.eldonfox.com/contact/" target="new">Contact me today</a> and lets get started!
        			</p>
        	    </div>);
        }
	},[resumeInfo]);
	    
	return (
		<section className="sectionPod resumeOverview">
			<h3 className="title">{ letterTitle }</h3>
			{ overLetter }
		</section>
	);
}
export default ResumeOverview;