import useFetch from './useFetch';
import LoadingBlock from './common/LoadingBlock';
import EditResume from './quilly/EditResume';
import DeleteResume from './quilly/DeleteResume';
import CreateResume from './quilly/CreateResume';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

const Quilly = () => {
	const { data:resumes, isLoading, error } = useFetch(process.env.REACT_APP_API_URL + 'resume',1);
	
	return (
		<Router>
			<div>
			  	<section className="pageBanner">
				  	<div className="pageBannerBG">
				  		<img src="./media/images/home.png" role="presentation" alt=""></img>
			  		</div>
					<h2 className="bannerTitle" id="watermark">
						Eldon Fox, Front-End Developer, Designer, and Watercolor Artist
					</h2>
					<img id="printWatermark" src="./media/images/watermark.png" role="presentation" alt=""></img>
				</section>
				<div className="sectionSet">
					<section className="sectionPod">
						<h3 className="title">Quilly</h3>
		    			<Link to={`/quilly/CreateResume`}>Create Resume</Link>
						<LoadingBlock isLoading={isLoading} error={error} />
						<table className="resumeList">
							<thead>
								<tr>
									<th>Submission Date</th>
									<th>Company</th>
									<th>Job Title</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
				    		{resumes && resumes.map((resume) => (
						    	<tr key={resume.id}>
					    			<td className="jobDate">{ resume.submission_date }</td>
					    			<td className="jobCompany">{ resume.organization }</td>
					    			<td className="jobTitle">{ resume.job_title }</td>
					    			<td>
						    			<Link to={`/quilly/EditResume/${resume.id}`}>Edit</Link> | 
						    			<Link to={`/quilly/DeleteResume/${resume.id}`}>Delete</Link> | 
						    			<Link to={`/${resume.id}`} target="_blank">URL</Link>
					    			</td>
					    		</tr>
				    		))}
				    		</tbody>
			    		</table>
		    		</section>
					<section className="sectionPod">
						<h3 className="title">Resume Details</h3>
						<Switch>
						<Route exact path="/Quilly/CreateResume/" render={props => <CreateResume {...props} />} />
						<Route path="/Quilly/EditResume/:id">
							<EditResume />
						</Route>
						<Route path="/Quilly/DeleteResume/:id">
							<DeleteResume />
						</Route>
						</Switch>
		    		</section>
	    		</div>
			</div>
		</Router>
	);
}
export default Quilly;