import { useState, useEffect } from 'react';
import useFetch from '../useFetch';
import LoadingBlock from '../common/LoadingBlock';
import { useParams, useHistory } from "react-router-dom";
/*import DatePicker from 'react-date-picker';*/

const EditResume = () => {
	const { id } = useParams();
	const { data:resumeInfo, isLoading, error } = useFetch(process.env.REACT_APP_API_URL + 'resume?id=' +  id  );
	const [ date, setDate ] = useState('');
	const [ org, setOrg ] = useState('');
	const [ title, setTitle ] = useState('');
	const [ letter, setLetter ] = useState('');
	const history = useHistory();
	
	
	const handleSubmit = (e) => {
		e.preventDefault();
		const application = {id, date, org, title, letter};
		console.log(JSON.stringify(application));
		
		fetch(process.env.REACT_APP_API_URL + 'resume', {
			method: 'PUT',
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(application)
		}).then(res => {
			console.log('new application added');
			console.log(res);
			history.push('/quilly');
		})
		.catch(error => {
			console.log(error.message);
		})
	}

	useEffect(() => {
        if(resumeInfo && typeof resumeInfo[0] !== 'undefined') {
			setDate(resumeInfo[0].submission_date);
			setOrg(resumeInfo[0].organization);
			setTitle(resumeInfo[0].job_title);
			setLetter(resumeInfo[0].coverLetter.trim());
        }
	},[resumeInfo]);
	
	return (
		<div>
			<LoadingBlock isLoading={isLoading} error={error} />
			<form onSubmit={handleSubmit}>
					<label>Date</label>
					<input 
						type="text"
						required
						value={date}
						onChange={(e) => setDate(e.target.value)}
					/>
					<label>Organization</label>
					<input 
						type="text"
						required
						value={org}
						onChange={(e) => setOrg(e.target.value)}
					/>
					<label>Job Title</label>
					<input 
						type="text"
						required
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
					<div className="editor">
						<label className="editorLabel">Cover Letter</label>
						<textarea
							required
							className="docBody"
							value={letter}
							onChange={(e) => setLetter(e.target.value)}></textarea>
					</div>
				<button>Save</button>
    		</form>
		</div>
	);
}

export default EditResume;