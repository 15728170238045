import useFetch from '../useFetch';
import LoadingBlock from '../common/LoadingBlock';

const ResumeJobs = () => {
	const { data:jobs, isLoading, error } = useFetch(process.env.REACT_APP_API_URL + 'jobs', 4, 'jobs');
	
	return (
		<section className="sectionPod resumeJobs">
			<h3 className="title">Jobs</h3>
			<LoadingBlock isLoading={isLoading} error={error} />
			<div className="jobsList">
	    		{jobs && jobs.map((job) => (
			    	<div className="jobPod" key={job.id}>
		    			<h4 className="jobTitle">{ job.title }</h4>
		    			<h5 className="jobCompany">{ job.company }</h5>
		    			<p className="jobDates">{ job.start } - { job.end || 'Present'}</p>
		    			<p className="jobDescription">{ job.description }</p>
		    		</div>
	    		))}
    		</div>
		</section>
	);
}
export default ResumeJobs;