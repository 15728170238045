import ResumeOverview from './resume/ResumeOverview';
import ResumeAbout from './resume/ResumeAbout';
import ResumeSkills from './resume/ResumeSkills';
import ResumeReferences from './resume/ResumeReferences';
import ResumeJobs from './resume/ResumeJobs';
import ResumeEdu from './resume/ResumeEdu';
//import TestClass from './TestClass';

const Resume = () => {
	/*localStorage.setItem('testVar', JSON.stringify({'test':'ping', 'var':'pong'}));
	const testing = JSON.parse(localStorage.getItem('testVar'));
	console.log(testing.test);
	console.log(testing.var);
	localStorage.removeItem('testVar');
	*/
	return (
		<div>
		  	<section className="pageBanner">
			  	<div className="pageBannerBG">
			  		<img src="./media/images/home.png" role="presentation" alt=""></img>
		  		</div>
				<h2 className="bannerTitle" id="watermark">
					Eldon Fox, Front-End Developer, Designer, and Watercolor Artist
				</h2>
				<img id="printWatermark" src="./media/images/watermark.png" role="presentation" alt="" height="185px" width="272px" ></img>
			</section>
			<div className="sectionSet">
				<div className="subPod">
				{/*
					<TestClass test="TestThis" url="http://www.eldonfox.com" />
					*/}
					<ResumeOverview />
					<ResumeAbout />
					<ResumeSkills />
				</div>
				<ResumeReferences />
				<div className="subPod">
					<ResumeJobs />
					<ResumeEdu />
				</div>
			</div>
		</div>
	);
}
export default Resume;