import { useState } from 'react';
import { useHistory } from "react-router-dom";

const CreateResume = () => {
	const [ date, setDate ] = useState('');
	const [ org, setOrg ] = useState('');
	const [ title, setTitle ] = useState('');
	const [ letter, setLetter ] = useState('');
	const history = useHistory();
	
	const handleSubmit = (e) => {
		e.preventDefault();
		const application = {date, org, title, letter};
		console.log(JSON.stringify(application));
		
		fetch(process.env.REACT_APP_API_URL + 'resume', {
			method: 'POST',
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(application)
		}).then(res => {
			console.log('new application added');
			console.log(res);
			history.push('/quilly');
		})
		.catch(error => {
			console.log(error.message);
		})
	}
	
	return(
		<div>
			<form onSubmit={handleSubmit}>
				<label>Date</label>
				<input 
					type="text"
					required
					value={date}
					onChange={(e) => setDate(e.target.value)}
				/>
				<label>Organization</label>
				<input 
					type="text"
					required
					value={org}
					onChange={(e) => setOrg(e.target.value)}
				/>
				<label>Job Title</label>
				<input 
					type="text"
					required
					value={title}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<div className="editor">
					<label className="editorLabel">Cover Letter</label>
					<textarea
						required
						className="docBody"
						value={letter}
						onChange={(e) => setLetter(e.target.value)}
					></textarea>
				</div>
				<button>Save</button>
			</form>
		</div>
	);
}
export default CreateResume;