import useFetch from '../useFetch';
import LoadingBlock from '../common/LoadingBlock';

const ResumeReferences = () => {
	const { data:recs, isLoading, error } = useFetch(process.env.REACT_APP_API_URL + 'recommendations', 3, 'recs');
	
	return(
		<section className="sectionPod resumeReferences">
			<h3 className="title">Recommendations</h3>
		    <div className="quoteSetWrapper">
		    	<LoadingBlock isLoading={isLoading} error={error} />
		    	<div className="quoteSet">
		    	{recs && recs.map((rec) => (
	    			<div className="quote" key={rec.id}>
		    			<p>
		    				"{ rec.recommendation }"
	    				</p>
	    				<p>
		    				<em className="quoteSource">-<strong className="name">{ rec.author }</strong>, { rec.auth_title }</em>
		    				<em className="quoteSource">{ rec.auth_org}</em>
		    			</p>
		    		</div>
    			))}
	    		</div>
		    	<div className="setFooter"></div>
		    </div>
		</section>
	);
}
export default ResumeReferences;